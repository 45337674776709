<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="modelRef" :labelCol="{ span: 4 }" :wrapperCol="{ span: 10 }" @finish="onSubmit">
				<a-form-item label="项目名称" name="title" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input v-model:value="modelRef.title" placeholder="请输入"></a-input>
				</a-form-item>

				<a-form-item label="项目logo" name="logoUrl" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-upload v-model:file-list="fileList" name="file" list-type="picture-card"
						class="avatar-uploader" action="/admin/ajaxUpload.do" :before-upload="beforeUpload"
						@change="handleChange">
						<div v-if="fileList.length < 1">
							<div class="ant-upload-text">上传</div>
						</div>
					</a-upload>
				</a-form-item>
				
				<a-form-item style="text-align: center;">
					<a-button type="primary" html-type="submit">提交</a-button>
				</a-form-item>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import { getProjectSettingDetail, updateProjectSetting } from '@/service/modules/system.js';
	export default ({
		data() {
			return {
				loading: false,
				modelRef: {},
				fileList: []
			}
		},
		created() {
			this.getData()
		},
		methods: {
			async onSubmit() {
				this.loading = true;
				let ret = await updateProjectSetting(this.modelRef);
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('修改成功');
				}
			},
			async getData() {
				this.loading = true;
				let ret = await getProjectSettingDetail({});
				this.loading = false;
				if (ret.code === 200) {
					this.modelRef = ret.data;
					this.fileList = [{ uid: ret.data.logoUrl, url: ret.data.logoUrl, name: ret.data.logoUrl }]
				}
			},
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('上传文件格式不正确');
				}
				const isLt1M = file.size / 1024 / 1024 < 2;
				if (!isLt1M) {
					this.$message.error('上传文件太大了');
				}
				return isJpgOrPng && isLt1M;
			},
			handleChange(info) {
				if (!info.file.status) {
					this.fileList.splice(this.fileList.length - 1, 1);
				}
				if (info.file.status === 'done') {
					// this.fileList = info.fileList;
					this.modelRef.imgUrl = [];
					const $fileList = [...info.fileList];
					$fileList.map(file => {
						if (file.response) {
							this.modelRef.logoUrl = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
		}
	})
</script>

<style>
</style>
